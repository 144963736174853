import React from "react";

export const StatsCard = (props) => {
  // Safe access to props.actions
  const lastAction = props.actions && props.actions.length > 0 ? props.actions[props.actions.length - 1] : null;
  const playerScore = lastAction ? lastAction.gamescore.substring(0, 1) : "";
  const playerScore2 = lastAction ? lastAction.gamescore.substring(2, 3) : "";

  // Safe access to props.score
  const scoreNewP1 = (props.score && props.score[0]) ? props.score[0] : "";
  const scoreNewP2 = (props.score && props.score[2]) ? props.score[2] : "";

  return (
    <div className="lg:w-full">
      <div className="hidden lg:flex lg:flex-row lg:ml-20">
        <div className="lg:mt-10">
          <div className="bg-white w-[350px] h-[250px] rounded-2xl shadow relative">
            <div className="lg:w-full">
              <div className="lg:flex lg:flex-row">
                <div className="bg-blue-100 lg:w-full lg:h-[55px] rounded-tl-lg rounded-tr-lg">
                  <div className="lg:m-5">
                    <h1>{props.category || "Category"}</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-center mt-10"></div>
            <div className="">
              <div className="lg:flex lg:flex-row ml-5 mb-5 items-center justify-between">
                <div className="flex items-center">
                  <img
                    width={30}
                    height={30}
                    src="https://messonstats.com:8443/images/raquet_tennis_ball_white.PNG"
                    alt="Tennis Ball"
                  />
                  <h1 className="ml-2 mt-1 text-xl">{props.player || "Player 1"}</h1>
                </div>
                <div className="flex items-center mr-10">
                  <h1 className="text-xl font-bold">{scoreNewP1}</h1>
                </div>
              </div>
              <div className="lg:flex lg:flex-row ml-5 mb-5 items-center justify-between">
                <div className="flex items-center">
                  <img
                    width={30}
                    height={30}
                    src="https://messonstats.com:8443/images/raquet_tennis_ball_white.PNG"
                    alt="Tennis Ball"
                  />
                  <h1 className="ml-2 mt-1 text-xl">{props.player2 || "Player 2"}</h1>
                </div>
                <div className="flex items-center mr-10">
                  <h1 className="text-xl font-bold">{scoreNewP2}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
