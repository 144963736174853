import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const logos = [
  'carlos_saint_new.JPG',
  'elite_broker_new.JPG',
  'geronimogomez_new.JPG',
  'guzmanariza_new.JPG',
  'koi_new.JPG',
  'la5tamendoza_new.JPG',
  'pinasupply_new.JPG',
  'puntomac_new.JPG',
  'simmons_new.JPG'
];
  
export const MobileLeftSideComponent = ({translation}) => {

  const { t } = translation

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % logos.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-front-500 flex flex-cols-3 ">
      {/* Mobile Content */}
      <div className="lg:hidden inline-block">
        <div>
          <h2 className="text-header-500 font-body font-bold text-2xl">
            {t("home_page_label_first")}
          </h2>
        </div>
        <div>
          <h1 className="text-black font-body font-bold text-2xl">
            MESSONSTATS.COM
          </h1>
        </div>
        <div className="mt-5">
          <h2 className="text-black font-body font-normal text-2xl">
            {t("home_page_label_second")}
          </h2>
        </div>
        <div>
          <img
            className="mt-5"
            width={500}
            src={
              "https://messonstats.com:8443/images/tennis_raquet_and_ball.jpg"
            }
            alt="Tennis Raquet"
          />
        </div>
        {/* Divider */}
        <div className="bg-orange-400 w-[100%] h-[2px] mt-10" />
        {/* Card Discover More */}
        <div>
          <div className="bg-white w-[350px] h-[370px] rounded-2xl mt-10 p-5 shadow ">
            <div className="content-center">
              <img
                width={30}
                height={30}
                src={
                  "https://messonstats.com:8443/images/raquet_tennis_ball_white.PNG"
                }
                alt="Tennis Raquet"
              />
            </div>
            <div className="font-bold text-sm uppercase text-header-500">
              {t("home_page_label_fifth")}
            </div>
            <div className="font-bold text-md uppercase text-header-500">
              {t("home_page_label_seventh")}
            </div>
            <div className="text-sm text-green-300 mt-5">
              {t("home_page_label_sixth")}
            </div>
            <div className="bg-orange-400 w-[150px] h-[50px] rounded-3xl mt-5 text-center content-center">
              <h2 className="font-bold font-body text-sm">{t("home_page_button_first")}</h2>
            </div>
          </div>
          {/* Divider */}
          <div className="bg-orange-400 w-[100%] h-[2px] mt-10" />
          <div>
            <div className="text-center m-10">
              <h2 className="font-bold font-header text-3xl">
                {t("home_page_label_spon_first")}
              </h2>
            </div>
            <div className="bg-white">
              <div className="flex items-center justify-center  h-[300px] self-center">
                {logos.map((logo, index) => (
                  <img
                    style={{
                      left: 0,
                      right: 0,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    width={200}
                    height={200}
                    key={index}
                    src={"https://messonstats.com:8443/images/" + logo}
                    alt={`Logo ${index}`}
                    className={`absolute transition-opacity duration-500 ${
                      index === activeIndex ? "opacity-100" : "opacity-0"
                    }`}
                    
                  />
                ))}
              </div>
            </div>
          </div>
          {/* Footer */}
          <div>
            <div className="bg-header-500 mt-5 mb-10 pt-5">
              <div className="flex flex-row">
                <Link to="/">
                  <div className="ml-5">
                    <img
                      width={125}
                      height={125}
                      src={
                        "https://messonstats.com:8443/images/logomessonstats_green.png"
                      }
                      alt="Tennis Raquet"
                    />
                  </div>
                </Link>
              </div>
              <div className="flex flex-row">
                <Link
                  to="/"
                  className="block px-4 py-2 font-header text-white hover:bg-header-500"
                >
                  {t("home")}
                </Link>
              </div>
              <div className="flex flex-row">
                <Link
                  to="/stats"
                  className="block px-4 py-2 font-header text-white hover:bg-header-500"
                >
                  {t("stats")}
                </Link>
              </div>
              <div className="flex flex-row">
                <Link
                  to="/live"
                  className="block px-4 py-2 font-header text-white hover:bg-header-500"
                >
                  {t("live")}
                </Link>
              </div>
              <div className="flex flex-row">
                <Link
                  to="/joinus"
                  className="block px-4 py-2 font-header text-white hover:bg-header-500"
                >
                  {t("aboutus")}                  
                </Link>
              </div>
              <div className="flex flex-row">
                <Link
                  to="/help"
                  className="block px-4 py-2 font-header text-white hover:bg-header-500"
                >
                  {t("help")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Desktop Content */}
      <div className="hidden md:block lg:pl-24 lg:mt-10 lg:ml-5 lg:w-[90%] lg:h-[5vh]">
        <div className="lg:text-left lg:m-3">
          <h1 className="lg:text-header-500 lg:font-body lg:font-bold lg:text-5xl">
            {t("home_page_label_first")}
          </h1>
        </div>
        <div className="lg:text-left lg:m-3">
          <h1 className="lg:text-black lg:font-body lg:font-bold lg:text-5xl">
            MESSONSTATS.COM
          </h1>
        </div>
        <div>
          <div className="lg:text-left lg:ml-3 lg:mt-5">
            <h2 className="lg:text-black lg:font-roboto lg:font-normal lg:text-xl">
              {t("home_page_label_second")}
            </h2>
          </div>
        </div>
        <div>
          <div className="lg:text-left lg:ml-3 lg:mt-5">
            <div className="lg:w-164 lg:h-32 lg:bg-orange-400">
              <div className="lg:flex lg:flex-row">
                <div className="hidden md:block lg:m-7">
                  <div className="lg:w-20 lg:h-20 lg:rounded-full lg:bg-header-500">
                    <div className="lg:pt-4 lg:pl-2">
                      <img
                        className="lg:w-15 lg:h-12"
                        src={
                          "https://messonstats.com:8443/images/raquet_tennis_ball_left_homepage.PNG"
                        }
                        alt="Tennis Raquet"
                      />
                    </div>
                  </div>
                </div>
                <div className="lg:m-5">
                  <h2 className="lg:font-body lg:text-white lg:text-xl lg:font-bold">
                    {t("home_page_label_third")}
                  </h2>
                  <h2 className="lg:font-body lg:text-white lg:text-sm lg:font-normal lg:mt-3">
                    {t("home_page_label_fourth")}{" "}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
