import React from "react";

const JoinUsView = ({translation}) => {

    const { t } = translation;

  return (
    <div className="m-10">
      <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-bold mb-4">{t("joinus_view_label_first")}</h2>

        <div className="mb-8">
          <p className="text-gray-700">
              {t("joinus_view_label_second")}
          </p>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-bold mb-2">{t("joinus_view_label_third")}</h3>
          <ul className="list-disc list-inside text-gray-700">
            <li>{t("joinus_view_label_fourth")}</li>
            <li>{t("joinus_view_label_fifth")}</li>
            <li>{t("joinus_view_label_sixth")}</li>
          </ul>
        </div>

        <div className="flex justify-center">
          <a
            href="https://wa.me/8098443270"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {t("joinus_view_button_first")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default JoinUsView;
