import React from "react";

export const StatsFooterPlayer2 = (props) => {

  const filteredData = props.stats.find(data => data._id.toLowerCase() === props.player2.toLowerCase());
  const ACE = filteredData ? filteredData.ACE : '';
  const DOUBLE_FAULT = filteredData ? filteredData.DOUBLE_FAULT : '';
  const RETURN_ERROR = filteredData ? filteredData.RETURN_ERROR : '';
  const FORCED_ERROR = filteredData ? filteredData.FORCED_ERROR : '';

  return (
    <div className="lg:w-full lg:h-[180px]">
      <div className="hidden lg:flex lg:flex-row lg:flex-cols-6 lg:gap-20 lg:bg-blue-950">
        <div className="lg:ml-10 lg:mt-10 lg:text-start">
          <div className="text-yellow-200 text-3xl font-header lg:w-[270px] ">
             <h1 className="text-gray-200 text-3xl font-header">{props.player2}</h1>
          </div>
        </div>
        <div className="lg:ml-10 lg:mt-10 lg:text-center lg:w-[100px]">
          <h1 className="text-gray-200 text-3xl font-header">{ACE}</h1>
        </div>
        <div className="lg:ml-10 lg:mt-10 lg:text-center lg:w-[100px]">
          <h1 className="text-gray-200 text-3xl font-header">{DOUBLE_FAULT}</h1>
        </div>
        <div className="lg:ml-10 lg:mt-10 lg:text-center lg:w-[100px]">
          <h1 className="text-gray-200 text-3xl font-header">{RETURN_ERROR}</h1>
        </div>
        <div className="lg:ml-10 lg:mt-10 lg:text-center lg:w-[100px]">
          <h1 className="text-gray-200 text-3xl font-header">{FORCED_ERROR}</h1>
        </div>
      </div>
    </div>
  );
};
