import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import './i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));

const AppWithRedux = (
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
  // <Provider store={store}>
  //   <React.StrictMode>
  //     <RouterProvider router={router} />
  //   </React.StrictMode>
  // </Provider>
);

root.render(AppWithRedux);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
