import { fetchStatsWinner } from "../../network/fetchStatsWinner";

export const FETCH_STATS_WINNER_SUCCESS = "FETCH_STATS_WINNER_SUCCESS";
export const FETCH_STATS_WINNER_FAILURE = "FETCH_STATS_WINNER_FAILURE";

export const fetchStatsSuccess = (plans) => ({
  type: FETCH_STATS_WINNER_SUCCESS,
  payload: plans,
});

export const fetchStatsFailure = (error) => ({
  type: FETCH_STATS_WINNER_FAILURE,
  payload: error,
});

export const fetchStatsWinnerData = (id) => {
  return (dispatch) => {
    return fetchStatsWinner(id)
      .then((responseData) => {
        dispatch(fetchStatsSuccess(responseData));
      })
      .catch((error) => {
        dispatch(fetchStatsFailure(error.message));
      });
  };
};