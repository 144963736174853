import React from "react";

export const StatsRight = (props) => {
  const filteredData = props.stats.find(
    (data) => data._id.toLowerCase() === props.player.toLowerCase()
  );
  const filteredData2 = props.stats.find(
    (data) => data._id.toLowerCase() === props.player2.toLowerCase()
  );

  const WINNER = filteredData ? filteredData.WINNER : "0";
  const UNFORCED_ERROR = filteredData ? filteredData.UNFORCED_ERROR : "0";
  const WINNER2 = filteredData2 ? filteredData2.WINNER : "0";
  const UNFORCED_ERROR2 = filteredData2 ? filteredData2.UNFORCED_ERROR : "0";
  const ACE = filteredData ? filteredData.ACE : "0";
  const ACE2 = filteredData2 ? filteredData2.ACE : "0";
  const DOUBLE_FAULT = filteredData ? filteredData.DOUBLE_FAULT : "0";
  const DOUBLE_FAULT2 = filteredData2 ? filteredData2.DOUBLE_FAULT : "0";
  const RETURN_ERROR = filteredData ? filteredData.RETURN_ERROR : "0";
  const RETURN_ERROR2 = filteredData2 ? filteredData2.RETURN_ERROR : "0";
  const FORCED_ERROR = filteredData ? filteredData.FORCED_ERROR : "0";
  const FORCED_ERROR2 = filteredData2 ? filteredData2.FORCED_ERROR : "0";

  const playerWinner = props.statswinner.find(item => item._id === props.player);
  const player2Winner = props.statswinner.find(item => item._id === props.player2);
  const playerUnforcedError = props.statsUnforcedError.find(item => item._id === props.player);
  const player2UnforcedError = props.statsUnforcedError.find(item => item._id === props.player2);

  var APPROACH_DROP = ""
  var DROP_SHOT = ""
  var LOB = ""
  var OVERHEAD = ""
  var WINNER_STATS = ""
  var UNFORCEDERROR_BACKHAND = ""
  var UNFORCEDERROR_FOREHAND = ""
  var APPROACH_DROP2 = ""
  var DROP_SHOT2 = ""
  var LOB2 = ""
  var OVERHEAD2 = ""
  var WINNER_STATS2 = ""
  var UNFORCEDERROR_BACKHAND2 = ""
  var UNFORCEDERROR_FOREHAND2 = ""

  if(playerWinner&&player2Winner&&playerUnforcedError&&player2UnforcedError){

    APPROACH_DROP = playerWinner.APPROACH_DROP
    DROP_SHOT = playerWinner.DROP_SHOT
    LOB = playerWinner.LOB
    OVERHEAD = playerWinner.OVERHEAD
    WINNER_STATS = playerWinner.WINNER
    UNFORCEDERROR_BACKHAND = playerUnforcedError.FOREHAND
    UNFORCEDERROR_FOREHAND = playerUnforcedError.BACKHAND
    APPROACH_DROP2 = player2Winner.APPROACH_DROP
    DROP_SHOT2 = player2Winner.DROP_SHOT
    LOB2 = player2Winner.LOB
    OVERHEAD2 = player2Winner.OVERHEAD
    WINNER_STATS2 = player2Winner.WINNER
    UNFORCEDERROR_BACKHAND2 = player2UnforcedError.FOREHAND
    UNFORCEDERROR_FOREHAND2 = player2UnforcedError.BACKHAND
  }

  return (
    <div className="lg:w-full">
      {/* Mobile Version */}
      <div className="flex flex-col lg:hidden">
        <div className="w-full h-[350px] bg-blue-900 gap-10">
          <div className="flex flex-row">
            <div className="w-[130px] h-[35px] m-10">
              <h1 className="text-yellow-200 text-3xl font-header">
                {props.player}
              </h1>
            </div>
            <div className="flex flex-col gap-3">
              <div className="mt-10">
                <div className="w-[200px] h-[35px] bg-blue-800">
                  <h1 className="text-black text-2xl font-header text-center">
                    {"WINNER"}
                  </h1>
                </div>
              </div>
              <div className="">
                <div className="w-[200px] h-[75px] bg-blue-800 content-center">
                  <h1 className="text-black text-3xl font-header text-center">
                    {WINNER}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-[130px] h-[35px] m-10 ">
              <h1 className="text-white text-3xl font-header">
                {props.player2}
              </h1>
            </div>
            <div className="flex flex-col gap-3">
              <div className="mt-10">
                <div className="w-[200px] h-[35px] bg-blue-800">
                  <h1 className="text-black text-2xl font-header text-center">
                    {"WINNER"}
                  </h1>
                </div>
              </div>
              <div className="">
                <div className="w-[200px] h-[75px] bg-blue-800 content-center">
                  <h1 className="text-black text-3xl font-header text-center">
                    {WINNER2}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:hidden">
        <div className="w-full h-[350px] bg-blue-900 gap-10">
          <div className="flex flex-row">
            <div className="w-[130px] h-[35px] m-10">
              <h1 className="text-yellow-200 text-3xl font-header">
                {props.player}
              </h1>
            </div>
            <div className="flex flex-col gap-3">
              <div className="mt-10">
                <div className="w-[200px] h-[35px] bg-blue-800">
                  <h1 className="text-black text-2xl font-header text-center">
                    {"UNFORCED ERROR"}
                  </h1>
                </div>
              </div>
              <div className="">
                <div className="w-[200px] h-[75px] bg-blue-800 content-center">
                  <h1 className="text-black text-3xl font-header text-center">
                    {UNFORCED_ERROR}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-[130px] h-[35px] m-10 ">
              <h1 className="text-white text-3xl font-header">
                {props.player2}
              </h1>
            </div>
            <div className="flex flex-col gap-3">
              <div className="mt-10">
                <div className="w-[200px] h-[35px] bg-blue-800">
                  <h1 className="text-black text-2xl font-header text-center">
                    {"UNFORCED ERROR"}
                  </h1>
                </div>
              </div>
              <div className="">
                <div className="w-[200px] h-[75px] bg-blue-800 content-center">
                  <h1 className="text-black text-3xl font-header text-center">
                    {UNFORCED_ERROR2}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:hidden">
        <div className="w-full h-[350px] bg-blue-900 gap-10">
          <div className="flex flex-row">
            <div className="w-[130px] h-[35px] m-10">
              <h1 className="text-yellow-200 text-3xl font-header">
                {props.player}
              </h1>
            </div>
            <div className="flex flex-col gap-3">
              <div className="mt-10">
                <div className="w-[200px] h-[35px] bg-blue-800">
                  <h1 className="text-black text-2xl font-header text-center">
                    {"ACE"}
                  </h1>
                </div>
              </div>
              <div className="">
                <div className="w-[200px] h-[75px] bg-blue-800 content-center">
                  <h1 className="text-black text-3xl font-header text-center">
                    {ACE}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-[130px] h-[35px] m-10 ">
              <h1 className="text-white text-3xl font-header">
                {props.player2}
              </h1>
            </div>
            <div className="flex flex-col gap-3">
              <div className="mt-10">
                <div className="w-[200px] h-[35px] bg-blue-800">
                  <h1 className="text-black text-2xl font-header text-center">
                    {"ACE"}
                  </h1>
                </div>
              </div>
              <div className="">
                <div className="w-[200px] h-[75px] bg-blue-800 content-center">
                  <h1 className="text-black text-3xl font-header text-center">
                    {ACE2}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:hidden">
        <div className="w-full h-[350px] bg-blue-900 gap-10">
          <div className="flex flex-row">
            <div className="w-[130px] h-[35px] m-10">
              <h1 className="text-yellow-200 text-3xl font-header">
                {props.player}
              </h1>
            </div>
            <div className="flex flex-col gap-3">
              <div className="mt-10">
                <div className="w-[200px] h-[35px] bg-blue-800">
                  <h1 className="text-black text-2xl font-header text-center">
                    {"ACE"}
                  </h1>
                </div>
              </div>
              <div className="">
                <div className="w-[200px] h-[75px] bg-blue-800 content-center">
                  <h1 className="text-black text-3xl font-header text-center">
                    {ACE}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-[130px] h-[35px] m-10 ">
              <h1 className="text-white text-3xl font-header">
                {props.player2}
              </h1>
            </div>
            <div className="flex flex-col gap-3">
              <div className="mt-10">
                <div className="w-[200px] h-[35px] bg-blue-800">
                  <h1 className="text-black text-2xl font-header text-center">
                    {"ACE"}
                  </h1>
                </div>
              </div>
              <div className="">
                <div className="w-[200px] h-[75px] bg-blue-800 content-center">
                  <h1 className="text-black text-3xl font-header text-center">
                    {ACE2}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:hidden">
        <div className="w-full h-[350px] bg-blue-900 gap-10">
          <div className="flex flex-row">
            <div className="w-[130px] h-[35px] m-10">
              <h1 className="text-yellow-200 text-3xl font-header">
                {props.player}
              </h1>
            </div>
            <div className="flex flex-col gap-3">
              <div className="mt-10">
                <div className="w-[200px] h-[35px] bg-blue-800">
                  <h1 className="text-black text-2xl font-header text-center">
                    {"DOUBLE FAULT"}
                  </h1>
                </div>
              </div>
              <div className="">
                <div className="w-[200px] h-[75px] bg-blue-800 content-center">
                  <h1 className="text-black text-3xl font-header text-center">
                    {DOUBLE_FAULT}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-[130px] h-[35px] m-10 ">
              <h1 className="text-white text-3xl font-header">
                {props.player2}
              </h1>
            </div>
            <div className="flex flex-col gap-3">
              <div className="mt-10">
                <div className="w-[200px] h-[35px] bg-blue-800">
                  <h1 className="text-black text-2xl font-header text-center">
                    {"DOUBLE FAULT"}
                  </h1>
                </div>
              </div>
              <div className="">
                <div className="w-[200px] h-[75px] bg-blue-800 content-center">
                  <h1 className="text-black text-3xl font-header text-center">
                    {DOUBLE_FAULT2}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:hidden">
        <div className="w-full h-[350px] bg-blue-900 gap-10">
          <div className="flex flex-row">
            <div className="w-[130px] h-[35px] m-10">
              <h1 className="text-yellow-200 text-3xl font-header">
                {props.player}
              </h1>
            </div>
            <div className="flex flex-col gap-3">
              <div className="mt-10">
                <div className="w-[200px] h-[35px] bg-blue-800">
                  <h1 className="text-black text-2xl font-header text-center">
                    {"RETURN ERROR"}
                  </h1>
                </div>
              </div>
              <div className="">
                <div className="w-[200px] h-[75px] bg-blue-800 content-center">
                  <h1 className="text-black text-3xl font-header text-center">
                    {RETURN_ERROR}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-[130px] h-[35px] m-10 ">
              <h1 className="text-white text-3xl font-header">
                {props.player2}
              </h1>
            </div>
            <div className="flex flex-col gap-3">
              <div className="mt-10">
                <div className="w-[200px] h-[35px] bg-blue-800">
                  <h1 className="text-black text-2xl font-header text-center">
                    {"RETURN ERROR"}
                  </h1>
                </div>
              </div>
              <div className="">
                <div className="w-[200px] h-[75px] bg-blue-800 content-center">
                  <h1 className="text-black text-3xl font-header text-center">
                    {RETURN_ERROR2}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:hidden">
        <div className="w-full h-[350px] bg-blue-900 gap-10">
          <div className="flex flex-row">
            <div className="w-[130px] h-[35px] m-10">
              <h1 className="text-yellow-200 text-3xl font-header">
                {props.player}
              </h1>
            </div>
            <div className="flex flex-col gap-3">
              <div className="mt-10">
                <div className="w-[200px] h-[35px] bg-blue-800">
                  <h1 className="text-black text-2xl font-header text-center">
                    {"FORCED ERROR"}
                  </h1>
                </div>
              </div>
              <div className="">
                <div className="w-[200px] h-[75px] bg-blue-800 content-center">
                  <h1 className="text-black text-3xl font-header text-center">
                    {FORCED_ERROR}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-[130px] h-[35px] m-10 ">
              <h1 className="text-white text-3xl font-header">
                {props.player2}
              </h1>
            </div>
            <div className="flex flex-col gap-3">
              <div className="mt-10">
                <div className="w-[200px] h-[35px] bg-blue-800">
                  <h1 className="text-black text-2xl font-header text-center">
                    {"FORCED ERROR"}
                  </h1>
                </div>
              </div>
              <div className="">
                <div className="w-[200px] h-[75px] bg-blue-800 content-center">
                  <h1 className="text-black text-3xl font-header text-center">
                    {FORCED_ERROR2}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Desktop View */}
      <div className="lg:flex lg:flex-row lg:gap-10">
        <div className="lg:flex lg:flex-col">
          <div className="lg:w-[400px] lg:h-[40px] lg:text-center lg:bg-blue-900 lg:shadow lg:m-1 lg:p-2">
            <h1 className="hidden lg:inline-block font-bold text-white">
              WINNER
            </h1>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-[200px] lg:h-[100px] lg:text-center lg:content-center lg:bg-blue-900 lg:shadow lg:m-1">
              <div className="lg:text-5xl lg:font-body lg:font-bold">
                <h1 className="hidden lg:inline-block text-white">{WINNER}</h1>
              </div>
            </div>
            {/* Progress bar */}
            <div className="lg:flex lg:flex-col">
              <div className="lg:flex lg:flex-row">
                <div className="hidden lg:inline-block text-white text-sm mr-5">
                  {"APPROACH_DROP"}
                </div>
                <div className="hidden lg:inline-block font-body text-white text-sm">
                  {APPROACH_DROP}
                </div>
              </div>
              <div className="lg:flex lg:flex-row">
                <div className="hidden lg:inline-block text-white text-sm mr-5">
                  {"DROP_SHOT"}
                </div>
                <div className="hidden lg:inline-block font-body text-white text-sm">
                  {DROP_SHOT}
                </div>
              </div>
              <div className="lg:flex lg:flex-row">
                <div className="hidden lg:inline-block text-white text-sm mr-5">
                  {"LOB"}
                </div>
                <div className="hidden lg:inline-block font-body text-white text-sm">
                  {LOB}
                </div>
              </div>
              <div className="lg:flex lg:flex-row">
                <div className="hidden lg:inline-block text-white text-sm mr-5">
                  {"OVERHEAD"}
                </div>
                <div className="hidden lg:inline-block font-body text-white text-sm">
                  {OVERHEAD}
                </div>
              </div>
              <div className="lg:flex lg:flex-row">
                <div className="hidden lg:inline-block text-white text-sm mr-5">
                  {"WINNER"}
                </div>
                <div className="hidden lg:inline-block font-body text-white text-sm">
                  {WINNER_STATS}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:flex flex-row">
          <h1>&nbsp;</h1>
        </div>
        <div className="lg:flex lg:flex-col">
          <div className="lg:w-[400px] lg:h-[40px] lg:text-center lg:bg-blue-900 lg:shadow lg:m-1 lg:p-2">
            <h1 className="hidden lg:inline-block font-bold text-white">
              UNFORCED ERROR
            </h1>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-[200px] lg:h-[100px] lg:text-center lg:content-center lg:bg-blue-900 lg:shadow lg:m-1">
              <div className="lg:text-5xl lg:font-body lg:font-bold">
                <h1 className="hidden lg:inline-block text-white">
                  {UNFORCED_ERROR}
                </h1>
              </div>
            </div>
            {/* Progress bar */}
            <div className="lg:flex lg:flex-col">
              <div className="lg:flex lg:flex-row">
                <div className="hidden lg:inline-block text-white text-sm mr-5">
                  {"BACKHAND"}
                </div>
                <div className="hidden lg:inline-block font-body text-white text-sm">
                  {UNFORCEDERROR_BACKHAND}
                </div>
              </div>
              <div className="lg:flex lg:flex-row">
                <div className="hidden lg:inline-block text-white text-sm mr-5">
                  {"FOREHAND"}
                </div>
                <div className="hidden lg:inline-block font-body text-white text-sm">
                  {UNFORCEDERROR_FOREHAND}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:flex lg:flex-row lg:gap-10">
        <div className="lg:flex lg:flex-col">
          <div className="lg:w-[400px] lg:h-[40px] lg:text-center lg:bg-blue-900 lg:shadow lg:m-1 lg:p-2">
            <h1 className="hidden lg:inline-block font-bold text-white">
              WINNER
            </h1>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-[200px] lg:h-[100px] lg:text-center lg:content-center lg:bg-blue-900 lg:shadow lg:m-1">
              <div className="lg:text-5xl lg:font-body lg:font-bold">
                <h1 className="hidden lg:inline-block text-white">{WINNER2}</h1>
              </div>
            </div>
            {/* Progress bar */}
            <div className="lg:flex lg:flex-col">
              <div className="lg:flex lg:flex-row">
                <div className="hidden lg:inline-block text-white text-sm mr-5">
                  {"APPROACH_DROP"}
                </div>
                <div className="hidden lg:inline-block font-body text-white text-sm">
                  {APPROACH_DROP2}
                </div>
              </div>
              <div className="lg:flex lg:flex-row">
                <div className="hidden lg:inline-block text-white text-sm mr-5">
                  {"DROP_SHOT"}
                </div>
                <div className="hidden lg:inline-block font-body text-white text-sm">
                  {DROP_SHOT2}
                </div>
              </div>
              <div className="lg:flex lg:flex-row">
                <div className="hidden lg:inline-block text-white text-sm mr-5">
                  {"LOB"}
                </div>
                <div className="hidden lg:inline-block font-body text-white text-sm">
                  {LOB2}
                </div>
              </div>
              <div className="lg:flex lg:flex-row">
                <div className="hidden lg:inline-block text-white text-sm mr-5">
                  {"OVERHEAD"}
                </div>
                <div className="hidden lg:inline-block font-body text-white text-sm">
                  {OVERHEAD2}
                </div>
              </div>
              <div className="lg:flex lg:flex-row">
                <div className="hidden lg:inline-block text-white text-sm mr-5">
                  {"WINNER"}
                </div>
                <div className="hidden lg:inline-block font-body text-white text-sm">
                  {WINNER_STATS2}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:flex flex-row">
          <h1>&nbsp;</h1>
        </div>
        <div className="lg:flex lg:flex-col">
          <div className="lg:w-[400px] lg:h-[40px] lg:text-center lg:bg-blue-900 lg:shadow lg:m-1 lg:p-2">
            <h1 className="hidden lg:inline-block font-bold text-white">
              UNFORCED ERROR
            </h1>
          </div>
          <div className="lg:flex flex-row">
            <div className="lg:w-[200px] lg:h-[100px] lg:text-center lg:content-center lg:bg-blue-900 lg:shadow lg:m-1">
              <div className="lg:text-5xl lg:font-body lg:font-bold">
                <h1 className="hidden lg:inline-block text-white">
                  {UNFORCED_ERROR2}
                </h1>
              </div>
            </div>
            {/* Progress bar */}
            <div className="lg:flex lg:flex-col">
              <div className="lg:flex lg:flex-row">
                <div className="hidden lg:inline-block text-white text-sm mr-5">
                  {"BACKHAND"}
                </div>
                <div className="hidden lg:inline-block font-body text-white text-sm">
                  {UNFORCEDERROR_BACKHAND2}
                </div>
              </div>
              <div className="lg:flex lg:flex-row">
                <div className="hidden lg:inline-block text-white text-sm mr-5">
                  {"FOREHAND"}
                </div>
                <div className="hidden lg:inline-block font-body text-white text-sm">
                  {UNFORCEDERROR_FOREHAND2}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
