import { API_BASE_URL } from "./API_BASE_URL";

export const fetchShop = () => {
  return fetch(API_BASE_URL + "/products")
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData) => responseData)
    .catch((error) => {
      throw new Error(error.message);
    });
};