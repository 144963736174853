import { fetchTodayGame } from "../../network/fetchTodayGame";

export const FETCH_TODAYGAME_SUCCESS = "FETCH_TODAYGAME_SUCCESS";
export const FETCH_TODAYGAME_FAILURE = "FETCH_TODAYGAME_FAILURE";

export const fetchTodayGameSuccess = (plans) => ({
  type: FETCH_TODAYGAME_SUCCESS,
  payload: plans,
});

export const fetchTodayGameFailure = (error) => ({
  type: FETCH_TODAYGAME_FAILURE,
  payload: error,
});

export const fetchTodayGameData = () => {
  return (dispatch) => {
    return fetchTodayGame()
      .then((responseData) => {
        dispatch(fetchTodayGameSuccess(responseData));
      })
      .catch((error) => {
        dispatch(fetchTodayGameFailure(error.message));
      });
  };
};
