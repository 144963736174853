const USD = {
    rate: 1.00
}
const DOP = {
    rate: 59.60
}
const EUR = {
    rate: 65.86
}

export { USD, DOP, EUR };
