import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import firebase from "firebase/compat/app";
import { event, view} from "../../firebaseEvents";
import { Link, useHistory } from "react-router-dom";

const LoginView = ({ analytics }) => {

  const history = useHistory(); // Use useHistory

  useEffect(()=>{
    // analytics.logEvent(view.login_view, { event: event.login });
    analytics.logEvent(view.login_view, { event: event.login });
  },[])

  const handleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);

    auth.onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken()
          .then((token) => {
            // console.log("User token:", user.displayName);
            // console.log("User token:", user.email);
            localStorage.setItem("token", token);
            localStorage.setItem("username", user.displayName);
            localStorage.setItem("email", user.email);
            // analytics.logEvent(view.login_view, { event: event.login_success });
            let event_item = {
              "user": localStorage.getItem("username") ,
              "event": event.login_success
            }
            analytics.logEvent(view.login_view, event_item );
            setTimeout(() => {
              history.push("/cart");
              window.location.reload();        
            }, 1000);
          })
          .catch((error) => {
            console.error("Error getting user token:", error);
          });
      } else {
        console.log("User is signed out");
      }
    });
  };
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h1 className="text-2xl font-bold mb-6 text-center">Login</h1>
        <form className="space-y-4">
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter your username"
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter your password"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Login
          </button>
        </form>
        <div className="mt-6 text-center">
          <button
            onClick={handleLogin}
            className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-red-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 488 512"
              className="h-5 w-5 mr-2 fill-current"
            >
              <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
            </svg>
            Sign in with Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
