import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../network/API_BASE_URL";

const BlogDetailsComponent = () => {
  const { id } = useParams();
  const blog = useSelector((state) => state.blog.blog);

  const filteredData = blog.find(
    (data) => data.id.toLowerCase() === id.toLowerCase()
  );
  const title = filteredData ? filteredData.title : '';
  const date = filteredData ? filteredData.id : '';
  const image = filteredData ? filteredData.image : '';
  const description = filteredData ? filteredData.description : '';

  const formatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="container mx-auto p-4 flex flex-col lg:flex-row">
      {/* Left column */}
      <div className="hidden lg:block lg:w-1/4 p-4">
        {/* Content for the left column on larger screens */}
      </div>

      {/* Center column */}
      <div className="w-full lg:w-1/2 p-4">
        <h1 className="text-3xl font-bold mb-4 text-center">{title}</h1>
        <p className="text-gray-500 mb-4 text-center">{formatDate(date)}</p>
        <div className="flex justify-center mb-4">
          <img
            src={`${API_BASE_URL}/images/${image}`}
            alt={title}
            style={{ width: "100%", maxWidth: "800px", height: "auto" }}
            className="object-cover"
          />
        </div>
        <p className="text-gray-700 mb-12" style={{ textAlign: "justify", margin: "0 50px" }}>
          {description}
        </p>
      </div>

      {/* Right column */}
      <div className="hidden lg:block lg:w-1/4 p-4">
        {/* Content for the right column on larger screens */}
      </div>
    </div>
  );
};

export default BlogDetailsComponent;
