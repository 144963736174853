import { API_BASE_URL } from "./API_BASE_URL";

export const fetchStatsUnforcedError = (id) => {
  let data = {id: id, action: "REPORT"}

  return fetch(API_BASE_URL + "/statsbymatchunforcederror", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData) => responseData)
    .catch((error) => {
      throw new Error(error.message);
    });
};
