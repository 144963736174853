import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom"; // Import useNavigate
import { useSelector, useDispatch } from "react-redux";
import { fetchShopData, addToCart } from "../../redux/actions/ShopActions";
import { API_BASE_URL } from "../../network/API_BASE_URL";
import { event, view} from "../../firebaseEvents";
import { USD, DOP } from "../../ExchangeRate";

const CardList = ({translation, analytics}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const shop = useSelector((state) => state.shop.shop);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const history = useHistory(); // Use useHistory
  const { t, i18n } = translation;

  useEffect(() => {
    dispatch(fetchShopData());
    // analytics.logEvent(view.shop_home_view, { event: event.select_item });
    let event_item = {
      "user": localStorage.getItem("username") ,
      "view": view.shop_home_view
    }
    analytics.logEvent(view.shop_home_view , event_item);
  }, [dispatch]);

  useEffect(() => { 
    setItems(shop);
    
  }, [shop]);

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    filterItems(term);
  };

  const filterItems = (term) => {
    const filteredItems = shop.filter(
      (item) =>
        item.title.toLowerCase().includes(term.toLowerCase()) ||
        item.subtitle.toLowerCase().includes(term.toLowerCase())
    );
    setItems(filteredItems);
  };

  const handleAddToCart = (item) => {

    let event_item = {
      "user": localStorage.getItem("username") ,
      "view": view.shop_home_view
    }
    analytics.logEvent(event.add_to_cart , event_item);

    const email = localStorage.getItem("email");
    if (!email) {
      history.push("/login"); // Redirect to login if email is not found
      return;
    }

    dispatch(addToCart(item));

    let addToCartObj = {
      "id": Date.now(),
      "productId": item.id,
      "name": item.title,
      "image": item.image,
      "quantity": 1,
      "price": item.price,
      "userId": email
    };

    fetch(API_BASE_URL+'/createcart', {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(addToCartObj)
    })
    .then((response) => response.json())
    .then((responseData) => {
        // console.log(responseData);
    });
  };

  return (
    <div className="p-4">
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search..."
        className="mb-4 p-2 border border-gray-300 rounded-lg w-full h-[75px]"
      />
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {items.map((item) => (
          <div key={item.id} className="bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden">
            <Link to={"/shopdetail/" + item.id}>
              <div className="flex justify-center items-center p-4">
                <img
                  src={item.image}
                  alt={item.title}
                  style={{ width: "300px", height: "250px" }}
                  className="object-cover"
                />
              </div>
              <div className="p-4">
                <h2 className="text-xl font-semibold mt-2">{item.title}</h2>
                <p className="text-gray-600 mt-1">{item.subtitle}</p>
                <p className="text-lg font-bold mt-2">{"RD$"}{(item.price * DOP.rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
              </div>
            </Link>
            <button
              onClick={() => handleAddToCart(item)}
              className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mt-2"
            >
              {t("addtocart")}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

const ShopComponent = ({ analytics, translation }) => {
  const { t } = translation;

  return (
    <div className="m-10">
      <CardList analytics={analytics} translation={translation} />
    </div>
  );
};

export default ShopComponent;
