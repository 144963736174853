import React from "react";

export const StatsCardPhone = (props) => {
  var playerScore = ""
  var playerScore2 = ""
  if
   (props.actions.slice(-1)[0]) {
    const lastAction = props.actions.slice(-1)[0];

    playerScore = lastAction.gamescore.substring(0, 1);
    playerScore2 = lastAction.gamescore.substring(2, 3);
  }

  const scoreNewP1 = (props.score && props.score[0]) ? props.score[0] : "";
  const scoreNewP2 = (props.score && props.score[2]) ? props.score[2] : "";


  return (
    <div className="w-full">
      <div className="flex flex-row flex-cols-3">
        <div className="mt-5">
          <div className="bg-white w-[200px] h-[150px] rounded-2xl shadow relative">
            <div className="bg-blue-100 lg:w-full lg:h-[55px] rounded-tl-lg rounded-tr-lg">
              <div className="m-5">
                <h1>{props.category}</h1>
              </div>
            </div>
            <div className="mt-7">
              <div className="flex flex-row ml-5 mb-5 items-center">
                <img
                  width={15}
                  height={15}
                  src="https://messonstats.com:8443/images/raquet_tennis_ball_white.PNG"
                  alt="Tennis Ball"
                />
                <h1 className="ml-2 mt-1 text-sm">{props.player}</h1>
                <div className="flex-grow flex items-center justify-end mr-5">
                  <h1 className="text-xl font-bold">
                    {/* {playerScore === "6" ? "7" : playerScore} */}
                    {scoreNewP1}
                  </h1>
                </div>
              </div>
              <div className="flex flex-row ml-5 mb-5 items-center">
                <img
                  width={15}
                  height={15}
                  src="https://messonstats.com:8443/images/raquet_tennis_ball_white.PNG"
                  alt="Tennis Ball"
                />
                <h1 className="ml-2 mt-1 text-sm">{props.player2}</h1>
                <div className="flex-grow flex items-center justify-end mr-5">
                  <h1 className="text-xl font-bold">
                    {/* {playerScore2 === "6" ? "7" : playerScore2} */}
                    {scoreNewP2}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
