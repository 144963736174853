import { FETCH_BLOG_SUCCESS, FETCH_BLOG_FAILURE } from "../actions/BlogActions";

  const initialState = {
    blog: [],
    error: null,
  };

  const blogReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_BLOG_SUCCESS:
        return {
          ...state,
          blog: action.payload,
          error: null,
        };
      case FETCH_BLOG_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export default blogReducer;