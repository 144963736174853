import React from 'react';

const HelpView = ({translation}) => {

    const { t } = translation;

  return (
    <div className='m-10'>
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">{t("help_view_label_first")}</h2>
      
      <div className="mb-8">
        <h3 className="text-xl font-bold mb-2">{t("help_view_label_second")}</h3>
        <p className="text-gray-700">
            {t("help_view_label_third")}
        </p>
      </div>
      
      <div className="mb-8">
        <h3 className="text-xl font-bold mb-2">{t("help_view_label_fourth")}</h3>
        <p className="text-gray-700">
        {t("help_view_label_fifth")}
        </p>
      </div>
      
      <div>
        <h3 className="text-xl font-bold mb-2">{t("help_view_label_sixth")}</h3>
        <p className="text-gray-700">{t("help_view_label_seventh")}<a href="mailto:messonstats@reymesson.com" className="text-blue-500">{t("help_view_label_eigth")}</a>.
        </p>
      </div>
    </div>
    </div>
  );
};

export default HelpView;
