import React, { useEffect} from 'react';
import { StatsFooter } from './stats/StatsFooter';
import { StatsLeft } from './stats/StatsLeft';
import { StatsRight } from './stats/StatsRight';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { fetchProductData } from '../redux/actions/productActions';
import { fetchStatsData } from '../redux/actions/statsActions';
import { StatsFooterPlayer } from './stats/StatsFooterPlayer';
import { StatsFooterPlayer2 } from './stats/StatsFooterPlayer2';
import { fetchStatsWinnerData } from '../redux/actions/statsWinnerActions';
import { fetchStatsUnforcedErrorData } from '../redux/actions/statsUnforcedErrorActions';

export const Stats = () => {
  const product = useSelector(state => state.product.product);
  const stats = useSelector(state => state.stats.stats);
  const statswinner = useSelector(state => state.statswinner.statswinner);
  const statsUnforcedError = useSelector(state => state.unforcedError.unforcedError);

  const { id } = useParams();

  const dispatch = useDispatch();

  const filteredData = product.find(data => data.id.toLowerCase() === id.toLowerCase());

  const player = filteredData ? filteredData.company : '';
  const player2 = filteredData ? filteredData.company2 : '';
  const description = filteredData ? filteredData.description : '';

  useEffect(() => {
    dispatch(fetchProductData());
    dispatch(fetchStatsData(description));
    dispatch(fetchStatsWinnerData(id));
    dispatch(fetchStatsUnforcedErrorData(id))
  }, []);

  return (
    <section className="lg:bg-blue-950">
      <div className="lg:w-full">
        <div>
          <div className="hidden lg:flex lg:justify-evenly">
            <div className="lg:flex-grow">
              <h1>&nbsp;</h1>
            </div>
            <div className="lg:flex-grow-0">
              <h1 className="lg:text-gray-100 lg:font-header lg:text-2xl">
                Match Statistics
              </h1>
            </div>
            <div className="lg:flex-grow">
              <h1>&nbsp;</h1>
            </div>
          </div>
          {/* Divider */}
          <div className="lg:bg-blue-950 lg:mt-10 lg:shadow" />
          <div className="lg:w-full">
            <div className="lg:flex lg:flex-row lg:flex-cols-2">
              <StatsLeft player={player} player2={player2} />
              <StatsRight 
                player={player}
                player2={player2}
                stats={stats}
                description={description}
                statswinner={statswinner}
                statsUnforcedError={statsUnforcedError}
              />
            </div>
            <div>
              <StatsFooter />
              <StatsFooterPlayer
                player={player}
                stats={stats}
                description={description}
              />
              <StatsFooterPlayer2
                player2={player2}
                stats={stats}
                description={description}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
