export const MobileRightSideComponent = () => {
  return (
    <div>
      <div className="w-[100%] lg:w-[100%] lg:h-[15vh] bg-header-500 content-center inline-block">
        <div>
          <img
            className="m-10"
            width={500}
            src={
              "https://messonstats.com:8443/images/tennis_raquet_and_ball.jpg"
            }
            alt="tennis raquet"
          />
        </div>
      </div>
    </div>
  );
};
