import React, { useState, useEffect } from "react";
import { MobileLeftSideComponent } from "./components/Mobile/MobileLeftSideComponent";
import { MobileRightSideComponent } from "./components/Mobile/MobileRightSideComponent";
import { Link } from "react-router-dom";
import "./App.css";
import { event, view } from "./firebaseEvents";

const logos = [
  'carlos_saint_new.JPG',
  'elite_broker_new.JPG',
  'geronimogomez_new.JPG',
  'guzmanariza_new.JPG',
  'koi_new.JPG',
  'la5tamendoza_new.JPG',
  'pinasupply_new.JPG',
  'puntomac_new.JPG',
  'simmons_new.JPG'
];
  
export const MobileComponent = ({analytics, translation}) => {

  const { t } = translation

  useEffect(() => {
    
    // analytics.logEvent(view.home_view , { event: "home_view" });
    let event = {
      "user": localStorage.getItem("username") ,
      "event": "home_view"
    }
    analytics.logEvent(view.home_view , { event: event });

    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % logos.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="w-full">
      <div className="flex flex-row bg-front-500 lg:flex lg:flex-row p-10">
        <div className="inline-block bg-front-500 lg:block lg:w-3/5 lg:bg-front-500 lg:mr-5 lg:h-[520px]">
          <MobileLeftSideComponent translation={translation} />
        </div>
        <div className="hidden lg:block lg:w-2/5 lg:bg-header-500">
          <MobileRightSideComponent />
        </div>
      </div>
      <div className="hidden lg:flex lg:flex-row lg:flex-cols-3 lg:ml-20">
        {/* Card #1 */}
        <div className="lg:m-10">
          <div className="bg-white w-[350px] h-[400px] rounded-2xl mt-10 p-5 shadow ">
            <div className="content-center">
              <img
                width={30}
                height={30}
                src={
                  "https://messonstats.com:8443/images/raquet_tennis_ball_white.PNG"
                }
                alt="Tennis Raquet"
              />
            </div>
            <div className="font-bold text-sm uppercase text-header-500">
              {t("home_page_label_fifth")}
            </div>
            <div className="font-bold text-md uppercase text-header-500">
              {t("home_page_label_seventh")}
            </div>
            <div className="text-sm text-green-300 mt-5">
              {t("home_page_label_sixth")}
            </div>
            <Link to="/guideview">
            <div className="bg-orange-400 w-[150px] h-[50px] rounded-3xl mt-5 text-center content-center">
              <h2 className="font-bold font-body text-sm">{t("home_page_button_first")}</h2>
            </div>
            </Link>
          </div>
        </div>
        {/* Card #2 */}
        <div className="lg:m-10">
          <div className="bg-white w-[350px] h-[400px] rounded-2xl mt-10 p-5 shadow ">
            <div className="content-center">
              <img
                width={30}
                height={30}
                src={
                  "https://messonstats.com:8443/images/raquet_tennis_ball_white.PNG"
                }
                alt="Tennis Raquet"
              />
            </div>
            <div className="font-bold text-sm uppercase text-header-500">
              {t("home_page_guide_view_label_first")}
            </div>
            <div className="font-bold text-md uppercase text-header-500">
             {t("home_page_guide_view_label_second")}
            </div>
            <div className="text-sm text-green-300 mt-5">
              {t("home_page_guide_view_label_third")}
            </div>
            <div className="bg-orange-400 w-[150px] h-[50px] rounded-3xl mt-5 text-center content-center">
              <Link to="/guideview" className="font-bold font-body text-sm">
                {t("home_page_guide_view_button_first")}
              </Link>
            </div>
          </div>
        </div>
        {/* Card #3 */}
        <div className="lg:m-10">
          <div className="bg-white w-[350px] h-[400px] rounded-2xl mt-10 p-5 shadow ">
            <div className="content-center">
              <img
                width={30}
                height={30}
                src={
                  "https://messonstats.com:8443/images/raquet_tennis_ball_white.PNG"
                }
                alt="Tennis Raquet"
              />
            </div>
            <div className="font-bold text-sm uppercase text-header-500">
              {t("home_page_joinus_view_label_first")}
            </div>
            <div className="font-bold text-md uppercase text-header-500">
              {t("home_page_joinus_view_label_second")}
            </div>
            <div className="text-sm text-green-300 mt-5">
              {t("home_page_joinus_view_label_third")}
            </div>
            <Link to="/joinus">            
            <div className="bg-orange-400 w-[150px] h-[50px] rounded-3xl mt-5 text-center content-center">
              <h3 className="font-bold font-body text-sm">              
                {t("home_page_joinus_view_button_first")}              
              </h3>
            </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex lg:flex-row lg:ml-10">
        <div className="lg:m-10 lg:text-center lg:flex lg:items-center lg:justify-center lg:w-full">
          {" "}
          <h2 className="lg:font-header lg:text-2xl">{t("home_page_label_spon_first")}</h2>
        </div>
      </div>
      <div className="hidden lg:flex lg:flex-row lg:ml-10">
        <div className="bg-white">
          <div className="flex items-center justify-center  h-[300px] self-center">
            {logos.map((logo, index) => (
              <img
                style={{
                  left: 0,
                  right: 0,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                width={200}
                height={200}
                key={index}
                src={"https://messonstats.com:8443/images/" + logo}
                alt={`Logo ${index}`}
                className={`absolute transition-opacity duration-500 ${
                  index === activeIndex ? "opacity-100" : "opacity-0"
                }`}
              />
            ))}
          </div>
        </div>
      </div>
      <div>
        <div className="lg:w-full lg:bg-header-500">
          <div className="hidden lg:flex lg:flex-row lg:flex-cols-5">
            <div className="lg:flex lg:flex-row">
              <Link to="/">
                <div className="lg:ml-5 lg:pr-10">
                  <img
                    width={125}
                    height={125}
                    src={
                      "https://messonstats.com:8443/images/logomessonstats_green.png"
                    }
                    alt="Tennis Raquet"
                  />
                </div>
              </Link>
            </div>            
            <div className="lg:flex lg:flex-row lg:p-10">
              <Link
                to="/"
                className="lg:block lg:px-4 lg:py-2 lg:font-header lg:text-white lg:hover:bg-header-500"
              >
                {t("home")}
              </Link>
            </div>
            <div className="lg:flex lg:flex-row lg:p-10">
              <Link
                to="/stats"
                className="lg:block lg:px-4 lg:py-2 lg:font-header lg:text-white lg:hover:bg-header-500"
              >
                {t("stats")}
              </Link>
            </div>
            <div className="lg:flex lg:flex-row lg:p-10">
              <Link
                to="/live"
                className="lg:block lg:px-4 lg:py-2 lg:font-header lg:text-white lg:hover:bg-header-500"
              >
                {t("live")}
              </Link>
            </div>
            <div className="lg:flex lg:flex-row lg:p-10">
              <Link
                to="/joinus"
                className="lg:block lg:px-4 lg:py-2 lg:font-header lg:text-white lg:hover:bg-header-500"
              >
                {t("aboutus")}
              </Link>
            </div>
            <div className="lg:flex lg:flex-row lg:p-10">
              <Link
                to="/help"
                className="lg:block lg:px-4 lg:py-2 lg:font-header lg:text-white lg:hover:bg-header-500"
              >
                {t("help")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
