// src/redux/reducers/shopReducer.js

import { 
  FETCH_SHOP_SUCCESS, 
  FETCH_SHOP_FAILURE, 
  SET_QUANTITY, 
  UPDATE_QUANTITY, 
  REMOVE_ITEM, 
  SET_CART, 
  ADD_TO_CART 
} from '../actions/ShopActions';

const initialState = {
  shop: [],
  quantity: 0, // This seems unused in your current logic, consider removing if not needed
  cart: [],
  error: null,
};

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHOP_SUCCESS:
      return {
        ...state,
        shop: action.payload,
        error: null,
      };
    case FETCH_SHOP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_TO_CART:
      return {
        ...state,
        cart: [...state.cart, action.payload],
      };
    case SET_CART:
      return {
        ...state,
        cart: action.payload,
      };
    case UPDATE_QUANTITY:
      return {
        ...state,
        cart: state.cart.map(item =>
          item.id === action.payload.id ? { ...item, quantity: action.payload.quantity } : item
        ),
      };
    case REMOVE_ITEM:
      return {
        ...state,
        cart: state.cart.filter(item => item.id !== action.payload),
      };
    default:
      return state;
  }
};

export default shopReducer;
