import { FETCH_STATS_WINNER_SUCCESS, FETCH_STATS_WINNER_FAILURE } from "../actions/statsWinnerActions";
  
  const initialState = {
    statswinner: [],
    error: null,
  };
  
  const statsWinnerReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_STATS_WINNER_SUCCESS:
        return {
          ...state,
          statswinner: action.payload,
          error: null,
        };
      case FETCH_STATS_WINNER_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default statsWinnerReducer;
  