import { fetchStatsUnforcedError } from "../../network/fetchStatsUnforcedError";

export const FETCH_STATS_UNFORCEDERROR_SUCCESS = "FETCH_STATS_UNFORCEDERROR_SUCCESS";
export const FETCH_STATS_UNFORCEDERROR_FAILURE = "FETCH_STATS_UNFORCEDERROR_FAILURE";

export const fetchStatsSuccess = (plans) => ({
  type: FETCH_STATS_UNFORCEDERROR_SUCCESS,
  payload: plans,
});

export const fetchStatsFailure = (error) => ({
  type: FETCH_STATS_UNFORCEDERROR_FAILURE,
  payload: error,
});

export const fetchStatsUnforcedErrorData = (id) => {
  return (dispatch) => {
    return fetchStatsUnforcedError(id)
      .then((responseData) => {
        dispatch(fetchStatsSuccess(responseData));
      })
      .catch((error) => {
        dispatch(fetchStatsFailure(error.message));
      });
  };
};