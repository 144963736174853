import { FETCH_TODAYGAME_SUCCESS, FETCH_TODAYGAME_FAILURE } from "../actions/todayGameActions";

  const initialState = {
    todayGame: [],
    error: null,
  };
  
  const todayGameReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TODAYGAME_SUCCESS:
        return {
          ...state,
          todayGame: action.payload,
          error: null,
        };
      case FETCH_TODAYGAME_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default todayGameReducer;
  