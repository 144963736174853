import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchStatsData } from "../../redux/actions/statsActions";
import { fetchProductData } from "../../redux/actions/productActions";
import { fetchTodayGameData } from "../../redux/actions/todayGameActions";
import { Link } from "react-router-dom";
import { StatsCard } from "../stats/StatsCard";
import { StatsCardPhone } from "../stats/StatsCardPhone";
import { event, view } from "../../firebaseEvents";

export const LiveMatchHome = ({analytics, translation}) => {
  const unsortedProduct = useSelector((state) => state.product.product); // Use a different name for unsorted product array
  const dispatch = useDispatch();
  const [product, setProduct] = useState([]);

  useEffect(() => {
    if (analytics) {
      let event_item = {
        "user": localStorage.getItem("username"),
        "view": view.live_view
      };
      analytics.logEvent(event.page_view, event_item);
    }
    dispatch(fetchStatsData());
    dispatch(fetchProductData());
  }, [dispatch, analytics]);

  useEffect(() => {
    const sortedProduct = [...unsortedProduct].sort((a, b) => {
      if (a.date > b.date) {
        return -1;
      }
      if (a.date < b.date) {
        return 1;
      }
      return 0;
    });
    setProduct(sortedProduct);
  }, [unsortedProduct]);

  if (!product || !Array.isArray(product)) {
    return <div>Loading...</div>;
  }
  return (
    <div className="lg:w-full">
      <div className="m-5">
        <h1 className="text-xl font-body font-bold">Live Match</h1>
      </div>
      <div className="hidden lg:grid lg:grid-cols-3 lg:gap-4 lg:ml-20">
        {product.map((item, index) => (
          <Link key={index} to={"/live/"+item.id}>
            <StatsCard
              key={index}
              category={item.category}
              player={item.company}
              player2={item.company2}
              actions={item.actions}
              score={item.score}
            />
          </Link>
        ))}
      </div>
      <div className="lg:hidden grid grid-cols-2 gap-1 ml-2">
        {product.map((item, index) => (
          <Link key={index} to={"/live/"+item.id}>
            <StatsCardPhone
              key={index}
              category={item.category}
              player={item.company}
              player2={item.company2}
              actions={item.actions}
              score={item.score}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};
