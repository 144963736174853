import React, { useEffect } from "react";
import { fetchBlogData } from "../../redux/actions/BlogActions";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../network/API_BASE_URL";

const BlogComponent = ({ translation }) => {
  const { t } = translation;

  const blog = useSelector((state) => state.blog.blog);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBlogData());
  }, [dispatch]);

  // Sort blogs based on the timestamp (assuming `id` is a timestamp)
  const sortedBlog = [...blog].sort((a, b) => b.id - a.id);

  const formatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="lg:w-full">
      <div className="lg:flex lg:flex-row">
        <div className="w-full flex justify-center items-center">
          <div className="lg:m-10">
            <h1 className="text-center text-3xl font-bold">Últimas novedades</h1>
          </div>
        </div>
      </div>
      <div className="lg:flex lg:flex-row">
        <div className="w-full flex justify-center items-center">
          <div className="">
            <h1 className="text-center">
              Encuentra las mejores estadísticas y mantente informado acerca de
              las novedades del tenis local e internacional.
            </h1>
            <h1 className="text-start">
              Aquí podrás ver los acontecimientos y sugerencias sobre tenis.
            </h1>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 m-10">
        {sortedBlog.map((item) => (
          <div key={item.id} className="bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden">
            <Link to={`/blogdetail/${item.id}`}>
              <div className="flex justify-center items-center p-4">
                <img
                  src={`${API_BASE_URL}/images/${item.image}`} alt={item.image}
                  style={{ width: "300px", height: "250px" }}
                  className="object-cover"
                />
              </div>
              <div className="p-4">
                <h2 className="text-xl font-semibold mt-2">{item.title}</h2>
                <p className="text-gray-600 mt-1">{item.subtitle}</p>
                <p className="text-gray-500 mt-2">{formatDate(item.id)}</p> {/* Display the formatted date */}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogComponent; 
