import { combineReducers } from "redux";
import statsReducer from "./statsReducer";
import productReducer from "./productReducer";
import todayGameReducer from "./todayGameReducer";
import statsWinnerReducer from "./statsWinnerReducer";
import statsUnforcedErrorReducer from "./statsUnforcedErrorReducer";
import blogReducer from "./blogReducer";
import shopReducer from "./shopReducer";
import cartReducer from "./cartReducer";

const rootReducer = combineReducers({
  stats: statsReducer,
  product: productReducer,
  todayGame: todayGameReducer,
  statswinner: statsWinnerReducer,
  unforcedError: statsUnforcedErrorReducer,
  blog: blogReducer,
  shop: shopReducer,
  cart: cartReducer
  // Add more reducers if needed
});

export default rootReducer;
