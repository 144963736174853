import React from "react";

export const StatsFooter = () => {
  return (
    <div className="lg:w-full">
      <div className="hidden lg:h-[100px] lg:flex lg:flex-row lg:flex-cols-6 lg:gap-20 lg:bg-blue-900">
        <div className="lg:ml-10 lg:mt-10 lg:text-center">
          <h1 className="text-yellow-200 text-3xl font-header lg:w-[300px] ">
            &nbsp;
          </h1>
        </div>
        <div className="lg:ml-10 lg:mt-10 lg:text-center">
          <h1 className="text-gray-200 text-3xl font-header">ACE</h1>
        </div>
        <div className="lg:ml-10 lg:mt-10 lg:text-center">
          <h1 className="text-gray-200 text-3xl font-header">DOUBLE FAULTS</h1>
        </div>
        <div className="lg:ml-10 lg:mt-10 lg:text-center">
          <h1 className="text-gray-200 text-3xl font-header">RETURN ERROR</h1>
        </div>
        <div className="lg:ml-10 lg:mt-10 lg:text-center">
          <h1 className="text-gray-200 text-3xl font-header">FORCED ERRORS</h1>
        </div>
      </div>
    </div>
  );
};
