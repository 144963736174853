import React from "react";

export const StatsLeft = (props) => {

  return (
    <div className="lg:w-full">
        <div className="hidden lg:flex lg:flex-col">
        <div className="lg:m-10">
            <h1 className="text-yellow-200 text-3xl font-header">{props.player}</h1>
        </div>
        <div>
            <h1 className="text-gray-200 text-3xl font-header">&nbsp;</h1>
        </div>
        <div className="lg:m-10">
            <h1 className="text-gray-200 text-3xl font-header">{props.player2}</h1>
        </div>

        </div>
    </div>
  );
};
