import { FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT_FAILURE } from "../actions/productActions";

  const initialState = {
    product: [],
    error: null,
  };
  
  const productReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PRODUCT_SUCCESS:
        return {
          ...state,
          product: action.payload,
          error: null,
        };
      case FETCH_PRODUCT_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default productReducer;
  