import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../network/API_BASE_URL";
import { event, view } from "../../firebaseEvents";

export const PaypalConfirmationView = ({analytics}) => {
  const { id } = useParams();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const payerId = urlParams.get("PayerID");

  console.log("Token:", token);
  console.log("Payer ID:", payerId);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [esimAccessOrderId, setEsimAccessOrderId] = useState("");

  useEffect(() => {

    let event_item = {
      "user": localStorage.getItem("username"),
      "value": 1.00,
      "view": view.purchase_view
    }
    analytics.logEvent(event.purchase , event_item);

    let order = {
      orderId: id,
      token: token,
      payerId: payerId,
    };
    axios
      .post(API_BASE_URL + "/paypalsuccessorder", order)
      .then((response) => {
        setEmail(response.data.email);
        // setName(response.data.name);
        setDate(response.data.createdAt);
        setOrderNumber(response.data.id);
        // setEsimAccessOrderId(response.data.esimAccessOrderId);
        // console.log(response);
      })
      .catch((error) => {
        // Handle error
        console.error("Error confirming order:", error);
      });


  }, []);

  const confirmCancelOrderSuccess = () => {
    window.location.href = "/";
  };

  return (
    <section>
      <div className="w-full">
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg">
            <div>
              <div className="flex flex-col justify-center items-center">
                <div className="text-7xl text-green-500">
                  <i className="fa fa-check-circle" />
                </div>
                <div className="text-2xl font-normal text-gray-600 m-5">
                  <h1>Payment Complete</h1>
                </div>
                <div className="justify-center text-center">
                  <div className="font-normal text-gray-600">
                    <h1>Thank you, your payment has been</h1>
                  </div>
                  <div className="font-normal text-gray-600">
                    <h1>successful. Your item has been </h1>
                  </div>
                  <div className="flex flex-row justify-center text-center">
                    <div className="font-normal text-gray-600">
                      <h1> sent to</h1>
                    </div>
                    <div className="pl-1 font-bold text-black">
                      <h1>{email}</h1>
                    </div>
                  </div>
                  <div className="flex flex-row mt-10 justify-start text-start">
                    <div className="pl-1  font-bold text-black">
                      <h1>{"ORDER DETAILS"}</h1>
                    </div>
                  </div>
                  <div className="flex flex-row mt-4 justify-start text-start">
                    <div className="pl-1  font-bold text-red-900">
                      <div className="border bg-red-900 border-red-900 bg rounded-3xl h-1 w-[125px]"></div>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex flex-row mt-10 justify-start text-start">
                      <div className="pl-1 text-xs font-bold text-black">
                        <h1>{"ORDER NUMBER:"}</h1>
                      </div>
                    </div>
                    <div className="flex flex-row mt-10 justify-start text-start">
                      <div className="pl-10 text-xs font-bold text-gray-500">
                        <h1>{orderNumber}</h1>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex flex-row mt-5 justify-start text-start">
                      <div className="pl-1 text-xs font-bold text-black">
                        <h1>{"ORDER DATE:"}</h1>
                      </div>
                    </div>
                    <div className="flex flex-row mt-5 ml-5 mb-10 justify-start text-start">
                      <div className="pl-10 text-xs font-bold text-gray-500">
                        <h1>{date}</h1>
                      </div>
                    </div>
                  </div>

                  <button
                    className="bg-gray-300 text-gray-700 px-10 py-2 rounded m-5"
                    onClick={confirmCancelOrderSuccess}
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
