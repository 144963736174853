import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuantity, removeItem, fetchCartItems } from '../../redux/actions/CartActions';
import axios from "axios";
import { API_BASE_URL } from "../../network/API_BASE_URL";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { event, view } from '../../firebaseEvents';
import { USD, DOP } from '../../ExchangeRate';

const Cart = ({analytics,translation}) => {
  const dispatch = useDispatch();
  const { cart: items, loading, error } = useSelector((state) => state.cart);
  const { t, i18n } = translation;

  useEffect(() => {

    let event_item = {
      "user": localStorage.getItem("username") ,
      "view": view.cart_view
    }
    analytics.logEvent(event.begin_checkout , event_item);
    dispatch(fetchCartItems());
  }, [dispatch]);

  const handleQuantityChange = (id, event) => {
    let order = {
      id: id,
      quantity: event.target.value
    };
    axios
      .post(API_BASE_URL + "/updatequantityitemcart", order)
      .then((response) => {
      })
      .catch((error) => {
        // Handle error
        console.error("Error confirming cart:", error);
      });
    const newQuantity = parseInt(event.target.value, 10);
    dispatch(updateQuantity(id, newQuantity));
  };

  const handleDelete = (id) => {
    let order = {
      id: id,
    };

    axios
      .post(API_BASE_URL + "/removeitemcart", order)
      .then((response) => {
      })
      .catch((error) => {
        // Handle error
        console.error("Error confirming order:", error);
      });
    dispatch(removeItem(id));
  };

  const calculateShipping = () => {
    let price = 5
    return price;
  };
  const calculateSubtotal = () => {
    return items.reduce((total, item) => total + item.quantity * item.price, 0);
  };

  const calculateTotal = () => {
    return calculateSubtotal()+calculateShipping();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>
    {t("cartempty")}{" "}
    <Link to="/shop" className="text-blue-500 hover:underline">
      {t("shop")}
    </Link>
    .
  </div>
  }

  const handleCancelOrderSuccess = () => {

    let event_item = {
      "user": localStorage.getItem("username") ,
      "view": view.cart_view
    }
    analytics.logEvent(event.begin_checkout , event_item);

    // analytics.logEvent(localStorage.getItem("email"), { event: "begin_checkout" });

    let orderId = Date.now();
    let order = {
      orderId: orderId,
      items: items,
      subtotal: calculateSubtotal(),
      total: calculateTotal()
    };

    axios
      .post(API_BASE_URL + "/createorder", order)
      .then((response) => {
        console.log(response.data.url);
      })
      .catch((error) => {
        // Handle error
        console.error("Error confirming order:", error);
      });

      let checkout = {
        orderId: orderId,
        description: localStorage.getItem("email") + " " + localStorage.getItem("username") + "-new-order",
        subtitle: localStorage.getItem("email") + " " + localStorage.getItem("username") + "-new-order",
        quantity: 1,
        price: calculateTotal()
      };

      axios
        .post(API_BASE_URL + "/checkout", { checkout })
        .then((response) => {
          window.open(response.data.href, "_self");
        })
        .catch((error) => {
          console.error("Error confirming order:", error);
        });
  }

  return (
    <div className="flex flex-col md:flex-row">
      {/* List of items */}
      <div className="flex-1 m-10 p-4 space-y-4">
        <h2 className='text-2xl m-10'>{t("cart_view_label_first")}</h2>
        {items.map(item => (
          <div key={item.id} className="flex flex-col md:flex-row items-center p-4 border border-gray-300 rounded-lg shadow-sm bg-white">
            <img src={item.image} alt={item.name} className="w-12 h-12 object-cover mb-4 md:mb-0 md:mr-4" />
            <div className="flex-1">
              <h3 className="text-lg font-semibold">{item.name}</h3>
              <h3 className="text-lg font-semibold">{"RD$"}{(item.price * DOP.rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
              <p className="text-sm text-gray-600">{t("cart_view_label_second")} {item.deliveryDate}</p>
              <div className="flex items-center mt-2">
                <select
                  value={item.quantity}
                  onChange={(e) => handleQuantityChange(item.id, e)}
                  className="p-2 border border-gray-300 rounded mr-4"
                >
                  {[1, 2, 3, 4, 5].map(num => (
                    <option key={num} value={num}>{num}</option>
                  ))}
                </select>
                <button
                  onClick={() => handleDelete(item.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  {t("cart_view_button_second")}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Summary section */}
      <div className="p-4 border-t md:border-l border-gray-300 bg-gray-50 md:w-1/4 md:flex-shrink-0">
        <h2 className="text-xl font-semibold mb-4">{t("cart_view_label_third")}</h2>
        <div className="mb-4">
          <div className="flex justify-between mb-2">
            <span>Subtotal:</span>
            <span>DOP${(calculateSubtotal() * DOP.rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
          </div>
          <div className="flex justify-between mb-2">
            <span>{t("cart_view_label_fourth")}</span>
            <span>{items.reduce((total, item) => total + item.quantity, 0)}</span>
          </div>
        </div>
        <div className="flex justify-between mb-4 font-semibold text-lg">
          <span>{t("cart_view_label_fifth")}</span>
          <span>DOP${(calculateShipping()*DOP.rate).toFixed(2)}</span>
        </div>
        <div className="flex justify-between mb-4 font-semibold text-lg">
          <span>Total:</span>
          <span>DOP${(calculateTotal() * DOP.rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
        </div>
        {/* <div className="flex justify-between mb-4 font-semibold text-lg">
          <span>Total:</span>
          <span>${(calculateTotal()*DOP.rate)*100}</span>
        </div> */}
        <button
          onClick={handleCancelOrderSuccess}
          className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          {t("cart_view_button_first")}{" "}
          <span>USD${(calculateTotal() * USD.rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>

        </button>
      </div>
    </div>
  );
};

export default Cart;
