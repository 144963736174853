import { fetchStats } from "../../network/fetchStats"; 

export const FETCH_STATS_SUCCESS = "FETCH_STATS_SUCCESS";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_STATS_FAILURE = "FETCH_STATS_FAILURE";
export const FETCH_PRODUCT_FAILURE = "FETCH_PRODUCT_FAILURE";

export const fetchStatsSuccess = (plans) => ({
  type: FETCH_STATS_SUCCESS,
  payload: plans,
});

export const fetchStatsFailure = (error) => ({
  type: FETCH_STATS_FAILURE,
  payload: error,
});

export const fetchStatsData = (description) => {
  return (dispatch) => {
    return fetchStats(description)
      .then((responseData) => {
        dispatch(fetchStatsSuccess(responseData));
      })
      .catch((error) => {
        dispatch(fetchStatsFailure(error.message));
      });
  };
};