import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom"; // Import useNavigate
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../network/API_BASE_URL";
import { useSelector, useDispatch } from "react-redux";
import { fetchShopData, addToCart } from "../../redux/actions/ShopActions";
import { event, view} from "../../firebaseEvents";
import { USD, DOP } from "../../ExchangeRate";

const ShopDetailsComponent = ({ translation, analytics }) => {
  const { t } = translation;

  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory(); // Use useHistory

  const shop = useSelector((state) => state.shop.shop);

  const item = shop.find((item) => item.id === parseInt(id));

  const [quantity, setQuantity] = useState(1);

  const decrement = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1); // Dispatch action to set quantity
    }
  };

  const increment = () => {
    setQuantity(quantity + 1); // Dispatch action to set quantity
  };

  useEffect(() => {

    // analytics.logEvent(view.shop_detail_view, { event: event.select_item });
    // analytics.logEvent(view.shop_detail_view, { event: event.view_item });
    let event_item = {
      "user": localStorage.getItem("username") ,
      "view": view.shop_detail_view
    }
    analytics.logEvent(event.select_item , event_item);

    let event_view_item = {
      "user": localStorage.getItem("username") ,
      "view": view.shop_detail_view
    }
    analytics.logEvent(event.view_item , event_view_item);
  },[])

  const handleAddToCart = (item) => {

    // analytics.logEvent(view.shop_detail_view, { event: event.add_to_cart });
    let event_item = {
      "user": localStorage.getItem("username") ,
      "view": view.shop_home_view
    }
    analytics.logEvent(event.add_to_cart , event_item);

    const email = localStorage.getItem("email");
    if (!email) {
      history.push("/login"); // Redirect to login if email is not found
      return;
    }

    dispatch(addToCart(item));

    let addToCartObj = {
      "id": Date.now(),
      "productId": item.id,
      "name": item.title,
      "image": item.image,
      "quantity": 1,
      "price": item.price,
      "userId": email
    };

    fetch(API_BASE_URL+'/createcart', {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(addToCartObj)
    })
    .then((response) => response.json())
    .then((responseData) => {
        // console.log(responseData);
    });
  };

  return (
    <div className="flex flex-col md:flex-row p-4 ml-10 mt-5">
      <div className="md:w-1/2">
        <img
          src={item.image}
          alt={item.title}
          className="w-[75%] h-auto object-cover rounded-lg"
        />
      </div>
      <div className="md:w-1/2 md:pl-4 mt-4 md:mt-0">
        <h1 className="text-2xl font-bold mb-2">{item.title}</h1>
        <p className="text-gray-600 text-lg mb-2">{item.subtitle}</p>
        <p className="text-lg font-bold mt-2">{"RD$"}{(item.price * DOP.rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>        <h2 className="text-xl font-semibold mb-2">Characteristics:</h2>
        <ul className="list-disc pl-5">
          {item.characteristics.map((char, index) => (
            <li key={index}>{char.description}</li>
          ))}
        </ul>     
        <button onClick={handleAddToCart}>
          <div className="shadow border rounded-3xl bg-orange-400 mt-10 w-full">
            <div className="pt-2 pb-5 pl-5 pr-5 flex flex-row justify-center items-center">
              <div className="text-xl font-semibold ml-2 mt-3">
                {t("addtocart")}
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default ShopDetailsComponent;
