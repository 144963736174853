import {
    FETCH_STATS_SUCCESS,
    FETCH_STATS_FAILURE,
  } from "../actions/statsActions";
  
  const initialState = {
    stats: [],
    error: null,
  };
  
  const statsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_STATS_SUCCESS:
        return {
          ...state,
          stats: action.payload,
          error: null,
        };
      case FETCH_STATS_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default statsReducer;
  