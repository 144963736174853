import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { MobileComponent } from "./MobileComponent";
import { Header } from "./components/Header";
import { StatsHome } from "./components/stats/StatsHome";
import { LiveMatchHome } from "./components/LiveMatch/LiveMatchHome";
import { Stats } from "./components/Stats";
import { LiveMatch } from "./components/LiveMatch";
import { useTranslation } from "react-i18next";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import HelpView from "./components/HelpView";
import JoinUsView from "./components/JoinUsView";
import GuideView from "./components/GuideView";
import BlogComponent from "./components/blog/BlogComponent";
import ShopComponent from "./components/shop/ShopComponent";
import ShopDetailsComponent from "./components/shop/ShopDetailsComponent";
import { PaypalConfirmationView } from "./components/shop/PaypalConfirmationView";
import Cart from "./components/shop/CartComponent";
import LoginView from "./components/login/LoginComponent";
import { auth, analytics } from "./firebase";
import BlogDetailsComponent from "./components/blog/BlogDetailsComponent";

const App = () => {
  const { t, i18n } = useTranslation();

  analytics.setAnalyticsCollectionEnabled(true);
  
  if (window.firebase && window.firebase.analytics) {
    analytics.setAnalyticsCollectionEnabled(true);
    window.firebase.analytics().setAnalyticsCollectionEnabled(true);
    window.firebase.analytics().setAnalyticsCollectionEnabled(true);
    window.firebase.analytics().debugModeEnabled = true;
  } 
  useEffect(() => {
    if (analytics) { 
      analytics.setAnalyticsCollectionEnabled(true);
      analytics.logEvent("page_view", { page_path: window.location.pathname });
    }
  }, [analytics]);

  return (
    <section>
      <Header translation={{ t, i18n }} analytics={analytics} />
      <div>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <MobileComponent {...props} analytics={analytics} translation={{ t, i18n }} />
            )}
          />
          <Route
            path="/mobile"
            render={(routeProps) => (
              <MobileComponent {...routeProps} analytics={analytics} />
            )}
          />
          <Route exact path="/stats" component={StatsHome} analytics={analytics} translation={{ t, i18n }} />
          <Route exact path="/stats/:id" component={Stats} analytics={analytics} translation={{ t, i18n }}  />
          <Route exact path="/live" component={LiveMatchHome} analytics={analytics} translation={{ t, i18n }}  />
          <Route
            exact
            path="/live/:id"
            render={(routeProps) => (
              <LiveMatch analytics={analytics} translation={{ t, i18n }} />
            )}
          />
          <Route
            path="/help"
            render={(routeProps) => <HelpView translation={{ t, i18n }} />}
          />
          <Route
            path="/joinus"
            render={(routeProps) => <JoinUsView translation={{ t, i18n }} />}
          />
          <Route
            path="/guideview"
            render={(routeProps) => <GuideView translation={{ t, i18n }} />}
          />
          <Route
            path="/blog"
            render={(routeProps) => <BlogComponent translation={{ t, i18n }} />}
          />
          <Route
            exact
            path="/blogdetail/:id"
            render={(routeProps) => (
              <BlogDetailsComponent
                translation={{ t, i18n }}
                analytics={analytics}
              />
            )}
          />
          <Route
            path="/shop"
            render={(routeProps) => <ShopComponent analytics={analytics} translation={{ t, i18n }} />}
          />
          <Route
            path="/cart"
            render={(routeProps) => <Cart analytics={analytics} translation={{ t, i18n }} />}
          />
          <Route
            exact
            path="/shopdetail/:id"
            render={(routeProps) => (
              <ShopDetailsComponent
                translation={{ t, i18n }}
                analytics={analytics}
              />
            )}
          />
          <Route
            exact
            path="/paypalsuccess/:id"
            render={(props) => (
              <PaypalConfirmationView analytics={analytics} />
            )}
          />
          <Route
            exact
            path="/login"
            render={(props) => (
              <LoginView analytics={analytics} />
            )}
          />
        </Switch>
      </div>
    </section>
  );
};

export default App;