import { fetchBlog } from "../../network/fetchBlog";

export const FETCH_BLOG_SUCCESS = "FETCH_BLOG_SUCCESS";
export const FETCH_BLOG_FAILURE = "FETCH_BLOG_FAILURE";

export const fetchBlogSuccess = (blogs) => ({
  type: FETCH_BLOG_SUCCESS,
  payload: blogs,
});

export const fetchBlogFailure = (error) => ({
  type: FETCH_BLOG_FAILURE,
  payload: error,
});

export const fetchBlogData = () => {
  return (dispatch) => {
    return fetchBlog()
      .then((responseData) => {
        dispatch(fetchBlogSuccess(responseData));
      })
      .catch((error) => {
        dispatch(fetchBlogFailure(error.message));
      });
  };
};