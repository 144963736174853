import { fetchShop } from "../../network/fetchShop";

export const FETCH_SHOP_SUCCESS = "FETCH_SHOP_SUCCESS";
export const FETCH_SHOP_FAILURE = "FETCH_SHOP_FAILURE";
export const SET_QUANTITY = "SET_QUANTITY";
export const SET_CART = "SET_CART";
export const ADD_TO_CART = 'ADD_TO_CART';
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export const REMOVE_ITEM = 'REMOVE_ITEM';

export const fetchShopSuccess = (shop) => ({
  type: FETCH_SHOP_SUCCESS,
  payload: shop,
});

export const setQuantity = (quantity) => ({
  type: SET_QUANTITY,
  payload: quantity,
});

export const addToCart = (item) => ({
  type: ADD_TO_CART,
  payload: item,
});

export const setCart = (cart) => ({
  type: SET_CART,
  payload: cart,
});

export const updateQuantity = (id, quantity) => ({
  type: UPDATE_QUANTITY,
  payload: { id, quantity },
});

export const removeItem = (id) => ({
  type: REMOVE_ITEM,
  payload: id,
});

export const fetchShopFailure = (error) => ({
  type: FETCH_SHOP_FAILURE,
  payload: error,
});

export const fetchShopData = () => {
  return (dispatch) => {
    return fetchShop()
      .then((responseData) => {
        dispatch(fetchShopSuccess(responseData));
      })
      .catch((error) => {
        dispatch(fetchShopFailure(error.message));
      });
  };
};