import { API_BASE_URL } from "../../network/API_BASE_URL";

// Action Types
export const FETCH_CART_REQUEST = 'FETCH_CART_REQUEST';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CART_FAILURE = 'FETCH_CART_FAILURE';
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export const REMOVE_ITEM = 'REMOVE_ITEM';


// Action Creators
export const fetchCartRequest = () => ({
  type: FETCH_CART_REQUEST,
});

export const fetchCartSuccess = (data) => ({
  type: FETCH_CART_SUCCESS,
  payload: data,
});

export const fetchCartFailure = (error) => ({
  type: FETCH_CART_FAILURE,
  payload: error,
});

export const updateQuantity = (id, quantity) => ({
  type: UPDATE_QUANTITY,
  payload: { id, quantity },
});

export const removeItem = (id) => ({
  type: REMOVE_ITEM,
  payload: id,
});

// Thunk to fetch cart data
export const fetchCartItems = () => async (dispatch) => {
  const email = localStorage.getItem('email');
  if (!email) {
    dispatch(fetchCartFailure('No email found in localStorage'));
    return;
  }

  dispatch(fetchCartRequest());

  try {
    const response = await fetch(API_BASE_URL + '/cart', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch cart items');
    }

    const data = await response.json();
    dispatch(fetchCartSuccess(data));
  } catch (error) {
    dispatch(fetchCartFailure(error.message));
  }
};
