const event = {
    login: "login",
    login_success: "login_success",
    select_item: "select_item",
    view_item: "view_item",
    add_to_cart: "add_to_cart",
    begin_checkout: "begin_checkout",
    purchase: "purchase",
    page_view: "page_view",
}

const view = {
    login_view: "login_view",
    home_view: "home_view",
    shop_home_view: "shop_home_view",
    shop_detail_view: "shop_detail_view",
    cart_view: "cart_view",
    purchase_view: "purchase_view",
    stats_view: "stats_view",
    live_view: "live_view",
    help_view: "help_view",
}


export { event, view };
