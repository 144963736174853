import { FETCH_STATS_UNFORCEDERROR_SUCCESS, FETCH_STATS_UNFORCEDERROR_FAILURE } from "../actions/statsUnforcedErrorActions";
  
  const initialState = {
    unforcedError: [],
    error: null,
  };
  
  const statsUnforcedErrorReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_STATS_UNFORCEDERROR_SUCCESS:
        return {
          ...state,
          unforcedError: action.payload,
          error: null,
        };
      case FETCH_STATS_UNFORCEDERROR_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default statsUnforcedErrorReducer;
  