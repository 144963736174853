import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAV4BtOZiSp7X5E0orcg9S_Bc5KyOHHxy4",
  authDomain: "messonstats.firebaseapp.com",
  projectId: "messonstats",
  storageBucket: "messonstats.appspot.com",
  messagingSenderId: "15908898092",
  appId: "1:15908898092:web:aaa34ef937c59b69eb1889",
  measurementId: "G-CWPHN9HB5B",
};

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();
const auth = firebase.auth();

export { auth, analytics };
