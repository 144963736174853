import React from "react";

const GuideView = ({ translation }) => {
  const { t } = translation;

  return (
    <div className="m-10">
      <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-bold mb-4">
          {t("guide_view_label_first")}
        </h2>

        <div className="mb-8">
          <p className="text-gray-700">
              {t("guide_view_label_second")}
          </p>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-bold mb-2">{t("guide_view_label_third")}</h3>
          <ul className="list-disc list-inside text-gray-700">
            <li>
                {t("guide_view_label_fourth")}
            </li>
            <li>{t("guide_view_label_fifth")}</li>
            <li>
                {t("guide_view_label_sixth")}
            </li>
            <li>{t("guide_view_label_seventh")}</li>
            <li>{t("guide_view_label_eighth")}</li>
            <li>{t("guide_view_label_nineth")}</li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-bold mb-2">{t("guide_view_extra_label_first")}</h3>
          <p className="text-gray-700">
              {t("guide_view_extra_label_second")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GuideView;
